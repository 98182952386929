/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    // Verifica se há um hash (para âncoras); caso contrário, faz o scroll para o topo
    return location.hash ? false : [0, 0];
  };
  